body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-gallery-icon:focus {
  outline: 2px solid #3379b700!important;
}

.image-gallery-image {
  position: relative!important;
  width: 100%!important;
}

.image-gallery-description {
  margin: 0!important;
  position: absolute!important;
  top: 90%!important;
  left: 50%!important;
  transform: translate(-50%, -50%)!important;
  bottom: -20px!important;
  max-height: 50px!important;
  font-family: "Quicksand", sans-serif!important;
  background: #021f33b2!important;
  border-radius: 5px!important;
  letter-spacing: 1px!important;
  font-weight: bold!important;
  width: 50%!important;
  font-size: 1.25rem!important;
}

.image-gallery-left-nav {
  left: -25%!important;
}

.image-gallery-right-nav {
  right: -25%!important;
}


@media (max-width: 792px) {
  .image-gallery-slides {
    background: none!important;
  }

  .image-gallery-right-nav {
    right: 8%!important;
    padding: 0!important;
  }

  .image-gallery-left-nav {
    left: 8%!important;
    padding: 0!important;
  }

  .image-gallery-description {
    font-size: 0.7rem!important;
    top: 200px!important;
    bottom: -10px!important;
  }

  .image-gallery-image {
    min-height: 260px!important;
  }
}


