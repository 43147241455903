@import "../../mixins.scss";
@import "../../variables.scss";

//Header
.header {
  height: 65px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.575);
  position: sticky;
  top: 0;
  background-color: $base-color;
  z-index: 100000000;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include mobile {
      margin-right: 5%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .list {
    &__element {
      padding: 0 26px;

      &:last-child {
        padding-right: 0;
      }
    }

    &__link {
      color: $white;
          font-family: 'Quicksand', sans-serif;
          font-weight: 700;
          font-size: 0.8rem;
          text-transform: uppercase;
          padding: 28px 18px 22px;
          border-bottom: 2px solid transparent;
          letter-spacing: 0.1875rem;
          text-decoration: none;

      &:hover {
          color: rgb(238, 247, 160);
          background-color: #61bcfdab;
          border-color: $yellow;
          transition: 1000ms;
          box-shadow: 3px 3px 6px rgba(0, 0, 0, .16);
      }
    }
  }

  &__list {
    display: flex;
    z-index: 1;

    @include mobile {
      position: absolute;
      left: 0;
      top: 65px;
      flex-direction: column;
      background-color: $base-color;
      width: 100%;
      padding-bottom: 20px;
      display: none;

      .list__element {
        padding: 0;
      }

      .list__link {
        display: block;
        padding: 14px 20px;
      }
    }
  }

  .menu {
    &__btn {
      display: none;
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;

      @include mobile {
        &:checked {
          ~ .header__list {
            display: flex;
          }

          ~ .menu__toggle {
            span {
              &:nth-child(1) {
                transform: rotate(45deg) translate(2px, 5px);
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:nth-child(3) {
                transform: rotate(-45deg) translate(2px, -5px);
              }
            }
          }
        }
      }
    }

    &__toggle {
      display: none;

      span {
          width: 20px;
          height: 2px;
          display: block;
          margin-bottom: 3px;
          background-color: $yellow;
          transition: .3s all ease-in-out;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include mobile {
        display: block;
      }
    }
  }
}

.logo {
  display: none;
  
  @include mobile {
  display: block;
  font-family: "Kalam", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  a {
    text-decoration: none;
    color: $yellow;
    float: left;
    padding-left: 15px;
  }
}
}

.arrowContainer {
  display: none;

  @include mobile {
    color: $yellow;
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    margin-right: 10px;
    display: flex;

    p {
      margin-right: 10px;
    }
  }
}

.arrow {
  border: solid $yellow;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

  //Banner
  .opening {
    background-color: $base-color;
    height: 100vh;
  
    &__image {
      background-image: $banner-gradient;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 5rem;
      font-family: "Kalam", Verdana, Geneva, Tahoma, sans-serif;
      text-shadow: 3px 3px 5px rgba(217, 255, 0, 0.274);

      @include mobile {
        box-shadow: 2px 2px 2px $black;
        background-image: $banner-gradient;
      }
  }
}

.animation {
  position: relative;

  h1 {
    font-size: 8rem;
    position: absolute;
    transform: translate(-50%, -50%);
    @include mobile {
      font-size: 3rem;
    }
  }
}

.two {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0em 0em 3em $neon,
                 0em 0em 2em $neon,
                 1px 1px 5px $neon,
                 0px 0px 15px $neon,
                 1px 0px 0px white,
                -1px 0px 2px rgba(0, 0, 0, .5);
}

/*
@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
  .one {
    color: $yellow;
    //-webkit-text-stroke: 2px $yellow;
  }
  */

  //Content
  .allContent {
    background-image: $main-gradient;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @include mobile {
      background-attachment: none;
      background-image: $mobile-gradient;
      background-position: center center;
      background-size: fixed;
  }
  }

  .line {
    border-top: solid $yellow 3px;
    border-radius: 50%;
    height: 3px;
    width: 30%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.671);
  }

  .statistics {
    padding: 85px 0;
    padding-bottom: 120px;
    background-color: #e2f3fe;
    margin: 0 auto;
    margin-bottom: 50px;

    @include mobile {
      padding: 50px 0 20px 0;
      margin-bottom: 0;
    }
  
    &__title {
      text-align: center;

      @include mobile {
        padding: 0;
      }
    }
  
    &__container {
      @include mobile {
        margin: 35px auto 50px;
        align-items: center;
        flex-direction: column;
      }
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    
    &__single {
      @include mobile {
        margin-bottom: 35px;
      
        &:last-child {
          margin-bottom: 0;
        }
      }

      max-width: 160px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      font-family: sans-serif;
      text-align-last: center;
  
      &:nth-child(1) {
        .statistics__circle {
          background-color:  $yellow;
        }
  
        .statistics__img {
          top: 0;
          left: 45%;
        }
      }
  
      &:nth-child(2) {
        .statistics__circle {
          background-color:  $yellow;
        }
  
        .statistics__img {
          top: 14px;
          left: 47%;
        }
      }
  
  
      &:nth-child(3) {
        .statistics__circle {
          background-color:  $yellow;
        }
  
        .statistics__img {
          top: 6px;
          left: 50%;
        }
      }
    }
  
    &__circle {
      border-radius: 50%;
      width: 63px;
      height: 63px;
      position: relative;
    }
  
    &__img {
      position: absolute;
    }
  
    &__number {
      font-size: 1.25rem;
      font-weight: 800;
      letter-spacing: 0.1875rem;
      margin-top: 13px;
    }
  
    &__description {
      color: rgb(24, 23, 23);
      margin-top: 19px;
      text-align: justify;
    }
  }

  .title {
    color: $yellow;;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
      text-decoration: none;
      padding-left: 25px;
      padding-top: 10px;
      font-size: 3.75rem;
      font-family: "Kalam", sans-serif;
      z-index: 100;

      @include mobile {
        font-size: 2.25rem;
        padding: 0;
      }
  }

  //About Us
  .about {
    padding: 30px 0;
    
    @include mobile {
      padding: 30px 0;
    }

    &__miniTitle {
      text-align: left;
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 5px;

      @include mobile {
        margin-right: 20px;
        margin-left: 20px;
      }
  }

    &__title {
      color: $yellow;;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
        font-family: 'Kalam', sans-serif;
        font-size: 3.75rem;
        margin-bottom: 55px;

        @include mobile {
          font-size: 3rem;
          margin-bottom: 20px;
          text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.747);
        }
    }
    
    &__container {
      @include tablet {
        padding: 0 15px;
        margin-bottom: 20px;
      }
      @include mobile {
        width: 85%;
      }

      margin: 0 auto;
      font-family: 'Quicksand', sans-serif;
      background-color: rgb(255, 255, 255);
      border: 1px solid #E8E9ED;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      border-radius: 5px;
      height: 100%;
      width: 70%;
    }

    &__left {
        margin: 55px;

        @include mobile {
          height: auto;
          margin: 30px 0;
        }
    }

    &__right {
      margin-right: 55px;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
        margin-bottom: 50px;
    }

    &__text {
        text-align: justify;
        font-size: 1.1rem;
        margin-bottom: 15px;

        span {
          font-weight: bold;
        }

        @include mobile {
          margin-right: 20px;
          margin-left: 20px;
        }
    }

    &__img {
      border-radius: 5px;
        width: 295px;
        height: auto;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);

        &:hover {
          transform: scale(1.05);
          transition: ease-in-out .5s;
        }
    }
}
  
  
  //Footer {
    .copyright {
      text-align: center;
      font-size: 0.8rem;
      padding-top: 65px;
      padding-bottom: 5px;

      @include mobile {
        padding: 0;
      }

      & a {
        text-decoration: none;
        color: inherit;
      }
    }

  .footer{
      background-color: $base-color;
      padding: 70px 0 0 0;
      font-family: "Quicksand", sans-serif;
      text-align: left;
      color: $white;

      @include mobile {
        padding: 20px 0;
      }

        &__container { 
          max-width: 1170px;
          margin: auto;
        }

        &__row {
          display: flex;
          flex-wrap: wrap;
        }

        ul {
          list-style: none;
        }

        &__col {
          width: 25%;
          padding: 0 15px;

          h4 {
            font-size: 1.2rem;
            color: $yellow;
            text-transform: capitalize;
            margin-bottom: 35px;
            font-weight: bold;
            position: relative;

          &::before {
            content: '';
            position: absolute;
            left:0;
            bottom: -10px;
            background-color: $accent;
            height: 2px;
            box-sizing: border-box;
            width: 50px;
          }

        }

          ul li:not(:last-child){
            margin-bottom: 10px;
          }

          ul li a{
            font-size: 16px;
            text-transform: capitalize;
            text-decoration: none;
            font-weight: 300;
            color: $white;
            display: block;
            transition: all 0.3s ease;
            font-weight: bold;
          }

          ul li a:hover{
            color: rgb(207, 202, 199);
            padding-left: 8px;
          }

          div span:not(:last-child){
            margin-bottom: 10px;
          }

          div span a {
            font-size: 16px;
            text-decoration: none;
            font-weight: 300;
            color: $white;
            display: block;
            transition: all 0.3s ease;
            font-weight: bold;
          }

          div span a:hover{
            color: rgb(207, 202, 199);
            padding-left: 8px;
          }
          
        }
    }
    .social-links a {
      display: inline-block;
      height: 40px;
      width: 40px;
      background-color: rgba(255,255,255,0.2);
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: $white;
      transition: all 0.5s ease;
    }
    
    .footer__col .social-links a:hover {
      background-color: $white;
      transform: scale(1.2);
    }

    
/*responsive*/
@media(max-width: 767px){
  .footer__col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer__col{
    width: 100%;
}
}

    .galleryMobile {
      @include mobile {
        max-width: 70vw;
        margin: 0 auto;
       }
    }