@mixin notebook {
    @media (max-width: 1352px) {
      @content;
    }
  }

  @mixin laptop {
    @media (max-width: 1260px) {
      @content;
    }
  }

  @mixin mobile {
    @media (max-width: 792px) {
      @content;
    }
  }

  @mixin special {
    @media (min-width: 767px) {
      @content;
    }
  }

  @mixin specialTablet {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @mixin tablet {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @mixin large {
    @media (min-width: 1500px) {
      @content;
    }
  }

  @mixin XXL {
    @media (min-width: 1750px) {
      @content;
    }
  }