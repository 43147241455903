@import "../mixins.scss";
@import "../variables.scss";

//Trivia
.trivia {
    min-height: 500px;
    background-image: $components-gradient;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 75px;
    padding-bottom: 150px;

    @include mobile {
        background-attachment: none;
      background-image: $mobile-gradient;
      background-position: center center;
      background-size: fixed;
        padding: 50px 30px 20px 30px;
    }

    &__miniTitle {
        text-align: left;
        font-size: 1.1rem;
        font-weight: bold;
        padding-bottom: 5px;

        @include mobile {
            margin-right: 20px;
            margin-left: 20px;
          }
    }

    &__title {
        color: rgb(217, 255, 0);;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
        font-family: 'Kalam', sans-serif;
        font-size: 3.75rem;
        margin-bottom: 35px;

        @include mobile {
            font-size: 3rem;
        }
    }
    &__container {
        font-family: 'Quicksand', sans-serif;
        background-color: #e2f3fe;
        border: 1px solid #E8E9ED;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        border-radius: 5px;
        height: 100%;
        width: 100%;

        @include mobile {
            padding: 0;
            background-color: $white;
            margin-bottom: 20px;
            width: 100%;
        }
    }

    &__left {
        margin: 55px;

        @include mobile {
            margin: 30px 0;
            padding: 10px 15px;
            height: auto;
        }
    }

    &__right {
        @include mobile {
            display: none;
        }

        margin-right: 55px;
    }

    &__text {
        text-align: justify;
        margin-bottom: 15px;

        @include mobile {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    &__img {
        border-radius: 5px;
        width: 295px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);

        &:hover {
            transform: scale(1.05);
            transition: ease-in-out .5s;
        }
    }
}

//Contact
.mobileMap {
    display: none;

    @include mobile {
        display: block;
    }
}
.contact {
    padding: 50px 0;
    background-image: $components-gradient;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;

    @include XXL {
        padding: 140px 30px;
    }

    @include mobile {
        background-attachment: none;
      background-image: $mobile-gradient;
      background-position: center center;
      background-size: fixed;
        padding: 50px 0 20px 0;
    }

    &__title {
        color: rgb(217, 255, 0);;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
        font-family: 'Kalam', sans-serif;
        font-size: 3.75rem;
        margin-bottom: 35px;

        @include mobile {
            font-size: 3rem;
        }
    }
    
    &__container {
        color: $black;;
        margin: 0 auto;
      font-family: 'Quicksand', sans-serif;
      background-color: $white;
      border: 1px solid #E8E9ED;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 50px;
      border-radius: 5px;
      height: 100%;
      width: 70%;

        @include mobile {
            width: 90%;
            padding: 0 50px;
        }

        @include special {
            width: 90%;
        }

        @include specialTablet {
            width: 80%;
        }
    }

    &__left {
        text-align: center;
        color: $black;;
    }

    &__right {
        @include mobile {
            display: none;
        }
    }

    &__icons {
        @include mobile {
            text-align: left;
        }
    }

    &__text {
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 15px;
        line-height: 1.5rem;
        @include tablet {
            font-size: 1.2rem;
        }
    }

    &__center {
        text-align: center;
        margin-bottom: 35px;

        @include mobile {
            margin-top: 25px;
        }
    }

    &__facebook {
        @include mobile {
            padding: 0;
        }
    }

    a {
        color: $black;;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            color: #14141481;
            text-decoration: underline;
            transition: 300ms;
        }
    }
}

//Prices
.pricesSection {
    background-image: $components-gradient;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
}
.prices {
    //min-height: 500px;
    padding: 30px;
    padding-bottom: 100px;
    height: 100%;

    @include XXL {
        padding: 140px 30px;
    }

    @include mobile {
        background-attachment: none;
      background-image: $mobile-gradient;
      background-position: center center;
      background-size: fixed;
        padding-bottom: 30px;
    }

    &__title {
        color: rgb(217, 255, 0);;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
        font-family: 'Kalam', sans-serif;
        font-size: 3.75rem;
        margin-bottom: 25px; 

        @include mobile {
            font-size: 3rem;
        }
    }
}

.additionalInfo {
    background-color: $base-color;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.747);
    

    @include mobile {
        background-color: #2e98e4;
        box-shadow: none;
    }

    a {
        color: rgba(233, 204, 38, 0.7);
        font-family: "Quicksand", sans-serif;
        font-size: 1.5rem;
        font-weight: bolder;
        text-decoration: none;

        @include mobile {
            font-size: 1rem;
            color: $black;;
        }
    }
}

//Rooms
.mobileGallery {
    display: none;

    @include mobile { 
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.carousel-image {
    @include mobile {
        width: -moz-fit-content!important;
        width: fit-content!important;
    }
}

.gallery {
    min-height: 500px;
    background-image: $components-gradient;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 55px;
    padding-bottom: 150px;

    @include mobile {
        padding: 50px 0 0 0;
        background-color: #e2f3fe6c;
        background-image: none;
    }

    &__title {
        color: rgb(217, 255, 0);
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.747);
        font-family: 'Kalam', sans-serif;
        font-size: 3.75rem;
        margin-bottom: 35px;

        @include mobile {
            font-size: 3rem;
        }
    }
    &__container {
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.3px;;
        background-color: #e2f3fe6c;
        border-radius: 5px;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.747);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        border-radius: 5px;
        padding-top: 50px;
        color: $white;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.747);
        
        @include mobile {
            padding: 50px 0;
            background-color: $white;
            box-shadow: none;
            color: $yellow;
        }
    }

    &__single {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile {
            display: none;
        }
    }

    &__photo {
        width: auto;
        max-width: 80%;
        cursor: pointer;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.747);
        margin-bottom: 35px;

        &:hover {
            transform: scale(1.05);
            transition: ease-in-out .5s;
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 205px;

    span {
        font-size: 1rem;
        font-weight: bold;
    }

    @include mobile {
        display: none;
    }
}

//Popup
#popup-root {
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 1000000000000000000;
}

.popup-content {
    min-height: 500px!important;
    position: absolute!important;
    left: -100%!important;
    top: 70%!important;
    transform: translate(-50%, -50%)!important;
    margin-top: 200px!important;

    @include large {
        margin-top: 270px!important;
    }
}

.thumbnails {
    display: none;
}

//Price Card
.priceCards {
    display: flex;
    justify-content: space-around;
    max-width: 100%;

    a {
        text-decoration: none;
        //display: block;
        color: inherit;
        width: 30%;

        @include mobile {
            margin-bottom: 20px;
            width: 90%;
        }
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.priceCard {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #18171785;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: .5s;
    width: 100%;

    @include mobile {
        margin-bottom: 20px;
        width: 90%;
    }

    &:hover {
        transform: scale(1.1);
    }

    &__one {
        border-top: 6px solid #68b1e6;
    }

    &__two {
        border-top: 6px solid $base-color;
    }

    &__three {
        border-top: 6px solid #2e98e4;
    }

    &__title {
        text-transform: uppercase;
        font-family: "Quicksand", sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 1.2px;
        margin: 20px 10px;
    }

    &__photo {
        border-radius: 5px;
        max-height: 150px;
        max-width: 200px;
        margin-bottom: 20px;
        width: 100%;
    }

    &__content {
        font-family: "Roboto", sans-serif;
    }

    &__month {
        margin-bottom: 5px;
    }

    &__price {
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__btn {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 20px;
        width: 100%;

        a {
            text-decoration: none;
            text-transform: uppercase;
            color: $white;
            font-family: "Kalam", sans-serif;
        }
    }

    &__btnOne {
        background-color:#68b1e6;

        &:hover {
            background-color: #68b1e677;
            a {
                color: $black;;
            }
        }
    }

    &__btnTwo {
        background-color: $base-color;

        &:hover {
            background-color: #40affe77;
            a {
                color: $black;;
            }
        }
    }

    &__btnThree {
        background-color: #2e98e4;

        &:hover {
            background-color: #1f608f77;
            a {
                color: $black;;
            }
        }
    }
}